import {
  CLEAR_INTEGRACAO_VENDA_DATA,
  FETCH_INTEGRACAO_VENDA_DATA,
  FETCH_INTEGRACAO_VENDA_FAILURE,
  FETCH_INTEGRACAO_VENDA_SUCCESS,
  FETCH_INTEGRACAO_VENDA_PDF,
  FETCH_INTEGRACAO_VENDA_PDF_FAILURE,
  FETCH_INTEGRACAO_VENDA_PDF_SUCCESS,
  FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE,
  FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS,
  SET_INTEGRACAO_VENDA_SORT_ORDER,
  CLEAR_INTEGRACAO_VENDA_SORT_ORDER,
  FETCH_INTEGRACAO_VENDA_TOTALS,
  FETCH_INTEGRACAO_VENDA_XLS,
  FETCH_INTEGRACAO_VENDA_XLS_SUCCESS,
  FETCH_INTEGRACAO_VENDA_XLS_FAILURE,
} from './types';

export const setIntegracaoVendaSortOrder = (field: any, order: any) => ({
  type: SET_INTEGRACAO_VENDA_SORT_ORDER,
  payload: { field, order },
});

export const clearIntegracaoVendaSortOrder = () => ({
  type: CLEAR_INTEGRACAO_VENDA_SORT_ORDER,
});

export const clearIntegracaoVendaData = () => ({
  type: CLEAR_INTEGRACAO_VENDA_DATA,
});

export const fetchIntegracaoVendaTotals = (data: any) => ({
  type: FETCH_INTEGRACAO_VENDA_TOTALS,
  payload: data,
});

export const fetchIntegracaoVendaTotalsSuccess = (data: any) => ({
  type: FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS,
  payload: data,
});

export const fetchIntegracaoVendaTotalsFailure = (error: any) => ({
  type: FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE,
  payload: error,
});

export const fetchIntegracaoVenda = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
  type: FETCH_INTEGRACAO_VENDA_DATA,
  payload,
});

export const fetchIntegracaoVendaSuccess = (data: any) => ({
  type: FETCH_INTEGRACAO_VENDA_SUCCESS,
  payload: data,
});

export const fetchIntegracaoVendaFailure = (error: any) => ({
  type: FETCH_INTEGRACAO_VENDA_FAILURE,
  payload: error,
});

export const fetchIntegracaoVendaXls = (payload: {
  company_id: number | number[];
  start_date: string;
  end_date: string;
  filters: {
    estabelecimento_numero?: string;
    numero_autorizacao?: string;
    numero_nsu?: string;
    adquirente_nome?: string;
    bandeira_nome?: string;
    banco_nome?: string;
    modalidade_id?: number;
  };
}) => ({
  type: FETCH_INTEGRACAO_VENDA_XLS,
  payload,
});

export const fetchIntegracaoVendaXlsSuccess = () => ({
  type: FETCH_INTEGRACAO_VENDA_XLS_SUCCESS,
});

export const fetchIntegracaoVendaXlsFailure = (error: any) => ({
  type: FETCH_INTEGRACAO_VENDA_XLS_FAILURE,
  payload: error,
});

export const fetchIntegracaoVendaPdf = (payload: {
  company_id: number | number[];
  start_date: string;
  end_date: string
  filters: {
    estabelecimento_numero?: string;
    numero_autorizacao?: string;
    numero_nsu?: string;
    adquirente_nome?: string;
    bandeira_nome?: string;
    banco_nome?: string;
    modalidade_id?: number;
  };
}) => ({
  type: FETCH_INTEGRACAO_VENDA_PDF,
  payload,
});

export const fetchIntegracaoVendaPdfSuccess = () => ({
  type: FETCH_INTEGRACAO_VENDA_PDF_SUCCESS,
});

export const fetchIntegracaoVendaPdfFailure = (error: any) => ({
  type: FETCH_INTEGRACAO_VENDA_PDF_FAILURE,
  payload: error,
});

