import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { useEffect, useState } from 'react';
import { StyledButton, StyledRangePickerContainer } from './styled';

interface RangeDatePickerProps {
  onChange: any;
  onFilter: (startDate: Dayjs, endDate: Dayjs) => void;
}

export const RangeDatePickerNavigation: React.FC<RangeDatePickerProps> = ({ onChange, onFilter, ...props }) => {
  const reduxStartDate = useSelector((state: RootState) => state.datas.startDate);
  const reduxEndDate = useSelector((state: RootState) => state.datas.endDate);
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs] | null>(null);

  useEffect(() => {
    if (reduxStartDate && reduxEndDate) {
      const startDay = dayjs(reduxStartDate);
      const endDay = dayjs(reduxEndDate);
      setSelectedDates([startDay, endDay]);
    }
  }, [reduxStartDate, reduxEndDate]);

  const handleDateSelection = (days: number) => {
    setSelectedDates(null);
    const startDate = dayjs().subtract(days, 'day');
    const endDate = dayjs();
    setTimeout(() => {
      setSelectedDates([startDate, endDate]);
      onChange([startDate, endDate], [startDate, endDate]);
      onFilter(startDate, endDate);
    }, 0);
  };

  return (
    <DatePicker.RangePicker
      {...props}
      disabled={vendaLoading || pagamentoLoading}
      placeholder={['Início', 'Fim']}
      format="DD/MM/YYYY"
      picker="date"
      value={selectedDates}
      onChange={(dates, dateStrings) => {
        setSelectedDates(dates as [Dayjs, Dayjs]);
        onChange(dates, dateStrings);
        if (dates && dates[0] && dates[1]) {
          onFilter(dates[0], dates[1]);
        }
      }}
      panelRender={(panelNode) => (
        <StyledRangePickerContainer>
          {panelNode}
          <div style={{ padding: '10px', textAlign: 'center' }}>
            <StyledButton onClick={() => handleDateSelection(0)}>Hoje</StyledButton>
            <StyledButton onClick={() => handleDateSelection(7)}>7 dias</StyledButton>
            <StyledButton onClick={() => handleDateSelection(15)}>15 dias</StyledButton>
            <StyledButton onClick={() => handleDateSelection(30)}>30 dias</StyledButton>
          </div>
        </StyledRangePickerContainer>
      )}
    />
  );
};
