import { SET_DATE_RANGE } from './types';

const initialState = {
  startDate: undefined,
  endDate: undefined,
};

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    default:
      return state;
  }
};
