import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchIntegracaoVendaSuccess,
  fetchIntegracaoVendaFailure,
  fetchIntegracaoVendaPdfSuccess,
  fetchIntegracaoVendaPdfFailure,
  fetchIntegracaoVendaTotalsSuccess,
  fetchIntegracaoVendaXlsSuccess,
  fetchIntegracaoVendaTotalsFailure,
  fetchIntegracaoVendaXlsFailure,
} from './actions';
import { FETCH_INTEGRACAO_VENDA_DATA, FETCH_INTEGRACAO_VENDA_PDF, FETCH_INTEGRACAO_VENDA_TOTALS, FETCH_INTEGRACAO_VENDA_XLS } from './types';
import { integracaoVendaApi, integracaoVendaPdfApi, integracaoVendaTotalsApi, integracaoVendaXlsApi } from '../../target-api/endpoints/integracaoVenda';
import { message } from 'antd';

export function* fetchIntegracaoVendaTotalsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const totalsIntegracaoVendaResponse = yield call(integracaoVendaTotalsApi, company_id, start_date, end_date);
    if (totalsIntegracaoVendaResponse && totalsIntegracaoVendaResponse.length <= 0) {
      message.warning('Nenhum total de integração venda encontrado!');
    } else {
      yield put(fetchIntegracaoVendaTotalsSuccess(totalsIntegracaoVendaResponse));
    }
  } catch (error: any) {
    yield put(fetchIntegracaoVendaTotalsFailure(error.message));
  }
}

export function* fetchIntegracaoVendaSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const integracaoVendaResponse = yield call(integracaoVendaApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder);
    if (integracaoVendaResponse && integracaoVendaResponse.length <= 0) {
      message.warning('Nenhum registro de integração venda encontrado!');
      yield put(fetchIntegracaoVendaSuccess([]));
    } else {
      yield put(fetchIntegracaoVendaSuccess(integracaoVendaResponse));
    }
  } catch (error: any) {
    yield put(fetchIntegracaoVendaFailure(error.message));
  }
}

export function* fetchIntegracaoVendaPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [integracaoVendaPdfResponse] = yield call(integracaoVendaPdfApi, company_id, start_date, end_date);
    if (integracaoVendaPdfResponse && integracaoVendaPdfResponse.length > 0) {
      yield put(fetchIntegracaoVendaPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de integracao venda em PDF!');
    }
  } catch (error: any) {
    yield put(fetchIntegracaoVendaPdfFailure(error.message));
  }
}

export function* fetchIntegracaoVendaXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [integracaoVendaResponse] = yield call(integracaoVendaXlsApi, company_id, start_date, end_date);
    if (integracaoVendaResponse && integracaoVendaResponse.length > 0) {
      yield put(fetchIntegracaoVendaXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de integracao venda em XLS!');
    }
  } catch (error: any) {
    yield put(fetchIntegracaoVendaXlsFailure(error.message));
  }
}

export function* watchIntegracaoVendaSaga() {
  yield takeLatest(FETCH_INTEGRACAO_VENDA_DATA, fetchIntegracaoVendaSaga);
  yield takeLatest(FETCH_INTEGRACAO_VENDA_TOTALS, fetchIntegracaoVendaTotalsSaga);
  yield takeLatest(FETCH_INTEGRACAO_VENDA_PDF, fetchIntegracaoVendaPdfSaga);
  yield takeLatest(FETCH_INTEGRACAO_VENDA_XLS, fetchIntegracaoVendaXlsSaga);
}