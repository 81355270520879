import api from '../../../services/axios';

export async function empresaApi(user_id: number): Promise<any> {
    const response = await api.get('/empresa', {
        params: { user_id },
    });
    return response.data;
}

export async function updateStatusEmpresaApi(empresaId: string, status: boolean): Promise<any> {
    const params = {
        empresa_id: empresaId,
        status: status
    };
    const response = await api.put('/empresa/update', params);
    return response.data;
}

export async function updateNomeEmpresaApi(empresaId: string, nome: string): Promise<any> {
    const params = {
        empresa_id: empresaId,
        novo_nome: nome
    };
    const response = await api.put('/empresa/rename', params);
    return response.data;
}
