import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  agendaRecebimentosApi, 
  agendaRecebimentosChartDataApi, 
  agendaRecebimentosPdfApi, 
  agendaRecebimentosTotalsApi, 
  agendaRecebimentosXlsApi 
} from '../../target-api/api';
import { message } from 'antd';
import { 
  fetchAgendaRecebimentosChartDataSuccess,
  fetchAgendaRecebimentosFailure, 
  fetchAgendaRecebimentosPdfFailure, 
  fetchAgendaRecebimentosPdfSuccess,
  fetchAgendaRecebimentosSuccess, 
  fetchAgendaRecebimentosTotalsFailure, 
  fetchAgendaRecebimentosTotalsSuccess, 
  fetchAgendaRecebimentosXlsFailure, 
  fetchAgendaRecebimentosXlsSuccess 
} from './actions';
import { 
  FETCH_AGENDA_RECEBIMENTOS_DATA, 
  FETCH_AGENDA_RECEBIMENTOS_PDF, 
  FETCH_AGENDA_RECEBIMENTOS_TOTALS, 
  FETCH_AGENDA_RECEBIMENTOS_XLS} from './types';


export function* fetchAgendaRecebimentosTotalsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [totalsAgendaRecebimentosResponse, agendaRecebimentosChartDataResponse] = yield all([call(agendaRecebimentosTotalsApi, company_id, start_date, end_date), call(agendaRecebimentosChartDataApi, company_id, start_date, end_date)]);
    if (totalsAgendaRecebimentosResponse && totalsAgendaRecebimentosResponse.length <= 0) {
      message.warning('Nenhum total de venda encontrado!');
    } else {
      yield put(fetchAgendaRecebimentosTotalsSuccess(totalsAgendaRecebimentosResponse));
    }
    if (agendaRecebimentosChartDataResponse && agendaRecebimentosChartDataResponse.length <= 0) {
      message.warning('Nenhum registro encontrado na agenda de recebimentos!');
    } else {
      yield put(fetchAgendaRecebimentosChartDataSuccess(agendaRecebimentosChartDataResponse));
    }
  } catch (error: any) {
    yield put(fetchAgendaRecebimentosTotalsFailure(error.message));
  }
}

export function* fetchAgendaRecebimentosSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const agendaRecebimentosResponse = yield call(agendaRecebimentosApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder);
    if (agendaRecebimentosResponse && agendaRecebimentosResponse.length <= 0) {
      message.warning('Nenhuma venda encontrada!');
      yield put(fetchAgendaRecebimentosSuccess([]));
    } else {
      yield put(fetchAgendaRecebimentosSuccess(agendaRecebimentosResponse));
    }
  } catch (error: any) {
    yield put(fetchAgendaRecebimentosFailure(error.message));
  }
}


export function* fetchAgendaRecebimentosPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [agendaRecebimentoPdfResponse] = yield call(agendaRecebimentosPdfApi, company_id, start_date, end_date);
    if (agendaRecebimentoPdfResponse && agendaRecebimentoPdfResponse.length > 0) {
      yield put(fetchAgendaRecebimentosPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de recebíveis em PDF!');
    }
  } catch (error: any) {
    yield put(fetchAgendaRecebimentosPdfFailure(error.message));
  }
}

export function* fetchAgendaRecebimentosXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [agendaRecebimentosResponse] = yield call(agendaRecebimentosXlsApi, company_id, start_date, end_date);
    if (agendaRecebimentosResponse && agendaRecebimentosResponse.length > 0) {
      yield put(fetchAgendaRecebimentosXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de recebíveis em XLS!');
    }
  } catch (error: any) {
    yield put(fetchAgendaRecebimentosXlsFailure(error.message));
  }
}

export function* watchAgendaRecebimentosSaga() {
  yield takeLatest(FETCH_AGENDA_RECEBIMENTOS_DATA, fetchAgendaRecebimentosSaga);
  yield takeLatest(FETCH_AGENDA_RECEBIMENTOS_TOTALS, fetchAgendaRecebimentosTotalsSaga);
  yield takeLatest(FETCH_AGENDA_RECEBIMENTOS_PDF, fetchAgendaRecebimentosPdfSaga);
  yield takeLatest(FETCH_AGENDA_RECEBIMENTOS_XLS, fetchAgendaRecebimentosXlsSaga);
}