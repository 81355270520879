import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, message } from 'antd';
import logoCard from '../../assets/login/logo-card-blue.svg';
import { Link, useLocation } from 'react-router-dom';
import { resetPassword } from '../../store/states/usuario/actions';
import { FormSide, ImageSide, LoginWrapper, StyledForm } from './styled';
import { getSubdomain } from '../../utils/validateCustomer';
import { RootState } from '../../store/types';

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const resetLoading = useSelector((state: RootState) => state.usuario.loading);
  const [form] = Form.useForm<unknown>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const onFinish = (value: any) => {
    try {
      const subdomain = getSubdomain();
      dispatch(resetPassword(subdomain, token, value.password));
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <LoginWrapper>
      <FormSide>
        <StyledForm
          style={{ background: '#fff', padding: 35, borderRadius: 12 }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div style={{ textAlign: 'left', marginBottom: 100 }}>
            <img src={logoCard} alt="Logo" style={{ width: 250 }} />
          </div>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Por favor, digite sua nova senha!',
              },
            ]}
          >
            <Input.Password placeholder="Digite sua nova senha" disabled={resetLoading} />
          </Form.Item>
          <Form.Item
            name="repeat-password"
            rules={[
              {
                required: true,
                message: 'Por favor, repita sua nova senha!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas não coincidem!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Repita sua nova senha" disabled={resetLoading} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={resetLoading}
              style={{ width: '100%', backgroundColor: '#252f64' }}
            >
              {!resetLoading ? 'Salvar Nova Senha' : 'Salvando, aguarde...'}
            </Button>
          </Form.Item>
          <Link to="/login">
            <Button type="primary" htmlType="submit" style={{ marginTop: 35, backgroundColor: '#252f64' }} loading={resetLoading}>Realizar Login</Button>
          </Link>
        </StyledForm>
      </FormSide>
      <ImageSide />
    </LoginWrapper>
  );
};

export default ResetPassword;
