import React, { useEffect, useState } from 'react';
import { Menu, Popover, Button, Badge } from 'antd';
import { BellOutlined, CalendarOutlined, LogoutOutlined, SearchOutlined, SettingOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { clearCompanyData, fetchCompany, updateCompanyID } from '../../store/states/empresa/actions';
import CustomSelect from '../Common/CustomSelect';
import ModalConfirmLogout from '../Modals/ModalConfirmLogout';
import { logout } from '../../store/states/auth/actions';
import { setDateRange } from '../../store/states/datas/actions';
import dayjs, { Dayjs } from 'dayjs';
import alertSound from '../../assets/sound-alerts/confirm.mp3';
import useSound from 'use-sound';
import ContentBreadcrumb from '../ContentBreadcrumb';
import { CustomSpan, StyledMenuItem, StyledSubMenu } from './styled';
import ModalPerfil from '../Modals/ModalPerfil';
import { clearVendaData, fetchVenda, fetchVendaTotals } from '../../store/states/venda/actions';
import { clearPagamentoData, fetchPagamento, fetchPagamentoAnalytics, fetchPagamentoAuditAnalytics, fetchPagamentoTotals } from '../../store/states/pagamento/actions';
import ModalEmpresa from '../Modals/ModalEmpresa';
import { RangeDatePickerNavigation } from '../Common/RangeDatePickerNavigation';
import NotificationList from '../NotificationList';
import { fetchNotification } from '../../store/states/notificacao/actions';
import { clearGestaoTaxa } from '../../store/states/gestao-taxa/actions';
import { fetchVendaAnalytics } from '../../store/states/venda/actions';
import { fetchAgendaRecebimentos, fetchAgendaRecebimentosTotals } from '../../store/states/agendaRecebimentos/actions';
import { fetchPagamentoConsolidado, fetchPagamentoConsolidadoFiltro } from '../../store/states/pagamentoConsolidado/actions';
import { fetchUserProfile } from '../../store/states/usuario/actions';
import { fetchBankingOfx } from '../../store/states/bankingOfx/actions';
import { fetchAntecipacaoEmprestimosGet, fetchAntecipacaoGet } from '../../store/states/antecipacao/actions';
import { fetchIntegracaoVenda, fetchIntegracaoVendaTotals } from '../../store/states/integracaoVenda/actions';

const Navigation: React.FC<any> = () => {
  const [isMobile] = useState(window.innerWidth < 768);
  const user = useSelector((state: RootState) => state.usuario.profile);
  const vendaLoading = useSelector((state: RootState) => state.venda.loading);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const agendaRecebimentosLoading = useSelector((state: RootState) => state.agendaRecebimentos.loading);
  const pagamentoConsolidadoLoading = useSelector((state: RootState) => state.pagamentoConsolidado.loading);
  const dispatch = useDispatch();
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const companies = useSelector((state: RootState) => state.empresa.data);
  const notifications = useSelector((state: RootState) => state.notification.data);
  const [showProfile, setShowProfile] = useState(false);
  const [showEmpresa, setShowEmpresa] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [playClick] = useSound(alertSound);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    user && user.usuariogrupoid && dispatch(fetchCompany({ user_id: user.usuarioid }));
    user && dispatch(fetchAntecipacaoGet());
  }, [dispatch, user]);

  const handleCompanySelect = (selectedCompanyIDs: number[]) => {
    dispatch(updateCompanyID(selectedCompanyIDs));
  };

  useEffect(() => {
    if (companies !== undefined && companies.length > 0) {
      dispatch(fetchNotification());
    }
  }, [companies]);

  const handleProfile = () => {
    setShowProfile(true);
  };

  const handleEmpresa = () => {
    setShowEmpresa(true);
  };

  const handleLogout = () => {
    playClick();
    setShowConfirmation(true);
  };

  const confirmLogout = () => {
    dispatch(clearCompanyData());
    dispatch(clearVendaData());
    dispatch(clearPagamentoData());
    dispatch(clearGestaoTaxa());
    dispatch(logout(true));
    setShowConfirmation(false);
  };

  const cancelLogout = () => {
    setShowConfirmation(false);
  };

  useEffect(() => {
    if (startDate && endDate) {
      filterData(startDate, endDate);
    }
  }, []);

  const handleDateChange = (dates: [Dayjs, Dayjs], dateStrings: [string, string]) => {
    dispatch(setDateRange(dates[0].toDate(), dates[1].toDate()));
    localStorage.setItem('startDate', dates[0].toDate().toString());
    localStorage.setItem('endDate', dates[1].toDate().toString());
  };

  const filterData = (startDate: Dayjs, endDate: Dayjs) => {
    dispatch(clearVendaData());
    dispatch(clearPagamentoData());
    if (companies !== undefined && companies.length > 0) {
      dispatch(fetchNotification());
    }
    dispatch(
      fetchVendaTotals({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchVendaAnalytics({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchVenda({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'VENDADATA',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchIntegracaoVenda({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'integracaodatavenda',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchIntegracaoVendaTotals({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    
    dispatch(
      fetchPagamentoAuditAnalytics({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchPagamentoAnalytics({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchPagamentoTotals({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchPagamento({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'PAGAMENTODATAPAGAMENTO',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchAgendaRecebimentosTotals({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }),
    );
    dispatch(
      fetchAgendaRecebimentos({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'VENDADATAPREVISAO',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchPagamentoConsolidado({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'PAGAMENTODATAPAGAMENTO',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchPagamentoConsolidadoFiltro({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 100000,
        sortField: 'PAGAMENTODATAPAGAMENTO',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchBankingOfx({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'ofxtransacaodatadeposito',
        sortOrder: 'DESC',
      }),
    );
    dispatch(
      fetchAntecipacaoEmprestimosGet({
        company_id: companyIDs,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        page: 1,
        pageSize: 10,
        sortField: 'antecipacaoemprestimodataassinatura',
        sortOrder: 'DESC'
      }),
    );
  };

  return (
    <>
      <Menu mode="horizontal" theme="light">
        <ContentBreadcrumb user={user} />
        <Menu.Item key="1">
          <CustomSelect key="customSelect" data={companies} selectedCompanyID={companyIDs} onChange={handleCompanySelect} />
        </Menu.Item>
        <Menu.Item key="2">
          {isMobile ? (
            <Popover content={<RangeDatePickerNavigation onChange={handleDateChange} onFilter={filterData} />} trigger="click">
              <Button style={{ width: '220px' }}>
                <CalendarOutlined /> Selecionar datas
              </Button>
            </Popover>
          ) : (
            <RangeDatePickerNavigation onChange={handleDateChange} onFilter={filterData} />
          )}
        </Menu.Item>
        <Menu.Item key="3">
          <Button onClick={() => filterData(startDate, endDate)} loading={vendaLoading || pagamentoLoading || agendaRecebimentosLoading || pagamentoConsolidadoLoading}>
            {!(vendaLoading || pagamentoLoading || agendaRecebimentosLoading || pagamentoConsolidadoLoading) ? (
              <>
                <SearchOutlined style={{ marginRight: '10px' }} />
                Pesquisar
              </>
            ) : (
              'Aguarde...'
            )}
          </Button>
        </Menu.Item>
        <Menu.Item key="4" style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }} onClick={handleProfile}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!user || user.usuariofoto === null || user.usuariofoto === '' ? (
              <UserOutlined style={{ fontSize: '20px', marginRight: 10 }} />
            ) : (
              <img src={user.usuariofoto} alt="Avatar do usuário" style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: 10 }} />
            )}
            <CustomSpan>{!user ? '...' : user.usuarionome}</CustomSpan>
          </div>
        </Menu.Item>
        <Menu.Item key="5">
          <Popover content={<NotificationList notifications={notifications !== undefined ? notifications : []} />} trigger="click">
            <Badge count={notifications !== undefined && notifications.length > 0 ? notifications.length : 0}>
              <BellOutlined style={{ fontSize: '20px' }} />
            </Badge>
          </Popover>
        </Menu.Item>
        <StyledSubMenu key="6" icon={<SettingOutlined />} title="Menu" popupOffset={[-10, 0]}>
          <StyledMenuItem key="7" onClick={handleProfile}>
            <UserOutlined style={{ marginRight: 10 }} /> Perfil
          </StyledMenuItem>
          <StyledMenuItem key="8" onClick={handleEmpresa}>
            <ShopOutlined style={{ marginRight: 10 }} /> Empresa(s)
          </StyledMenuItem>
          <StyledMenuItem key="9" onClick={handleLogout}>
            <LogoutOutlined style={{ marginRight: 10 }} /> Logout
          </StyledMenuItem>
        </StyledSubMenu>
      </Menu>
      {showProfile && <ModalPerfil isOpen={showProfile} onClose={() => setShowProfile(false)} />}
      {showEmpresa && <ModalEmpresa isOpen={showEmpresa} empresas={companies} onClose={() => setShowEmpresa(false)} />}
      {showConfirmation && <ModalConfirmLogout confirmLogout={confirmLogout} cancelLogout={cancelLogout} onclose={cancelLogout} />}
    </>
  );
};

export default Navigation;
