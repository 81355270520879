import moment from 'moment';
import api from '../../../services/axios';
import { message } from 'antd';
import saveAs from 'file-saver';

export async function antecipacaoGetApi(): Promise<any> {
    try {
        const response = await api.get('/antecipacao/dados/', {
            baseURL: process.env.REACT_APP_INTEGRACAO_API,
            validateStatus: (status: number) => status >= 200 && status < 500,
        });
        if (response.status !== 200) {
            console.warn(`Aviso: A requisição não foi bem-sucedida, status: ${response.status}`);
            return response;
        }
        return response;
    } catch (error: any) {
        console.error('Erro ao obter dados da API:', error.message || error);
        throw new Error('Erro ao obter dados da API');
    }
}

export async function antecipacaoPostApi(json_data: Object): Promise<any> {
    try {
        const response = await api.post('/antecipacao/dados/', json_data, {
            baseURL: process.env.REACT_APP_INTEGRACAO_API,
            validateStatus: (status: number) => status >= 200 && status < 500,
        });
        if (response.status !== 200) {
            console.warn(`Aviso: A requisição não foi bem-sucedida, status: ${response.status}`);
            return response;
        }
        return response;
    } catch (error: any) {
        console.error('Erro ao obter dados da API:', error.message || error);
        throw new Error('Erro ao obter dados da API');
    }
}

export async function antecipacaoDeleteContaApi(conta_id: number): Promise<any> {
    try {
        const response = await api.delete(`/antecipacao/dados/${conta_id}`, {
            baseURL: process.env.REACT_APP_INTEGRACAO_API,
            validateStatus: (status: number) => status >= 200 && status < 500,
        });
        if (response.status !== 200) {
            console.warn(`Aviso: A requisição não foi bem-sucedida, status: ${response.status}`);
            return response;
        }
        return response;
    } catch (error: any) {
        console.error('Erro ao obter dados da API:', error.message || error);
        throw new Error('Erro ao obter dados da API');
    }
}

export async function antecipacaoEmprestimosGetApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/antecipacao/emprestimos/';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function AntecipacaoEmprestimosPdfApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/antecipacao/emprestimos/export/pdf';
    const params = new URLSearchParams({
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
      for (const id of company_id) {
        params.append('company_id', id.toString());
      }
    } else {
      params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value !== undefined && value !== null && value !== '') {
        params.append(key, value.toString());
      }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
      const response = await api.get(url, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'relatorio_antecipacao.pdf');
    } catch (error) {
      message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
  }
  
  export async function AntecipacaoEmprestimosXlsApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/antecipacao/emprestimos/export/xls';
    const params = new URLSearchParams({
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
      for (const id of company_id) {
        params.append('company_id', id.toString());
      }
    } else {
      params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (value !== undefined && value !== null && value !== '') {
        params.append(key, value.toString());
      }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
      const response = await api.get(url, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.ms-excel',
        },
      });
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, 'relatorio_antecipacao.xlsx');
    } catch (error) {
      message.error(`Erro ao baixar o arquivo XLSX: ${error}`);
    }
  }
  