import { Tooltip } from 'antd';
import { adquirenteNomeParaImagem, bancoImagens } from '../../styles/globalInterfaces';

export interface CollapseFilterProps {
  isCollapseOpen: boolean;
  setIsCollapseOpen: (isOpen: boolean) => void;
  setShowAcquirer: (show: boolean) => void;
  showAcquirer: boolean;
  handleAdquirenteSelect: (adquirenteName: any) => void;
  handleBancoSelect?: (bancoName: any) => void;
  activeFilter: any;
  adquirentes: any[];
  bancos?: any[];
  selectedAdquirentes: any[];
  selectedBanco?: string | null;
}

export const AdquirentesList = ({ onSelect, activeFilters, adquirentes }: { onSelect: (adquirenteName: any) => void, activeFilters: any[], adquirentes: any[] }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    {adquirentes.map((item) => (
      <div
        key={item.adquirenteid}
        style={{
          margin: '25px 0 25px 60px',
          cursor: 'pointer',
          borderRadius: 10,
          padding: 2,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          boxShadow: activeFilters.includes(item.adquirentenome) ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          transform: activeFilters.includes(item.adquirentenome) ? 'scale(1.5)' : 'scale(1.2)',
          backgroundColor: activeFilters.includes(item.adquirentenome) ? '#252f64' : 'transparent',
        }}
        onClick={() => onSelect(item.adquirentenome)}
      >
        <Tooltip title={item.adquirentenome} placement="top">
          <img src={adquirenteNomeParaImagem[item.adquirentenome.toLowerCase().replace(/\s/g, '')]} alt={item.adquirentenome} style={{ width: 60, height: 60, borderRadius: 10 }} />
        </Tooltip>
      </div>
    ))}
  </div>
);

export const BancosList: React.FC<any> = ({ onSelect, activeFilter, bancos }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    {bancos?.map((item: any) => (
      <div
        key={item.bancoid}
        style={{
          margin: '25px 0 25px 60px',
          cursor: 'pointer',
          borderRadius: 10,
          padding: 2,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          boxShadow: activeFilter === item.banconome ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          transform: activeFilter === item.banconome ? 'scale(1.5)' : 'scale(1.2)',
          backgroundColor: activeFilter === item.banconome ? '#252f64' : 'transparent',
        }}
        onClick={() => onSelect(item.banconome)}
      >
        <img src={bancoImagens[item.bancoid]} alt={item.name} style={{ width: 60, height: 60, borderRadius: 10, backgroundColor: 'transparent' }} />
      </div>
    ))}
  </div>
);
