import { Reducer } from 'react';
import {
  FETCH_ANTECIPACAO_DELETE_CONTA_DATA,
  FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE,
  FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS,
  FETCH_ANTECIPACAO_EMPRESTIMOS_DATA,
  FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE,
  FETCH_ANTECIPACAO_EMPRESTIMOS_PDF,
  FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE,
  FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS,
  FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS,
  FETCH_ANTECIPACAO_EMPRESTIMOS_XLS,
  FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE,
  FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS,
  FETCH_ANTECIPACAO_GET_DATA,
  FETCH_ANTECIPACAO_GET_FAILURE,
  FETCH_ANTECIPACAO_GET_SUCCESS,
  FETCH_ANTECIPACAO_POST_DATA,
  FETCH_ANTECIPACAO_POST_FAILURE,
  FETCH_ANTECIPACAO_POST_SUCCESS,
  RESET_URL_DATA,
} from './types';

const AntecipacaoState = {
  data_get: [],
  data_post: {},
  data_emprestimos_get: {},
  loading_get: false,
  loading_post: false,
  loading_delete: false,
  loading_emprestimos: false,
  loading_emprestimos_export: false,
  error: null,
};

export const reducer: Reducer<any, any> = (state = AntecipacaoState, action: any) => {
  switch (action.type) {
    case FETCH_ANTECIPACAO_GET_DATA:
      return { ...state, loading_get: true };
    case FETCH_ANTECIPACAO_GET_SUCCESS:
      return { ...state, loading_get: false, data_get: action.payload || [] };
    case FETCH_ANTECIPACAO_GET_FAILURE:
      return { ...state, loading_get: false, error: action.payload };
    case FETCH_ANTECIPACAO_POST_DATA:
      return { ...state, loading_post: true };
    case FETCH_ANTECIPACAO_POST_SUCCESS:
      return { ...state, loading_post: false, data_post: action.payload || [] };
    case FETCH_ANTECIPACAO_POST_FAILURE:
      return { ...state, loading_post: false, error: action.payload };
    case RESET_URL_DATA:
      return {
        ...state,
        data_post: {},
      };
    case FETCH_ANTECIPACAO_DELETE_CONTA_DATA:
      return { ...state, loading_delete: true };
    case FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS:
      return { ...state, loading_delete: false };
    case FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE:
      return { ...state, loading_delete: false, error: action.payload };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_DATA:
      return { ...state, loading_emprestimos: true };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS:
      return { ...state, loading_emprestimos: false, data_emprestimos_get: action.payload || {} };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE:
      return { ...state, loading_emprestimos: false, error: action.payload };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_PDF:
      return { ...state, loading_emprestimos_export: true };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS:
      return { ...state, loading_emprestimos_export: false };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE:
      return { ...state, loading_emprestimos_export: false, error: action.payload };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_XLS:
      return { ...state, loading_emprestimos_export: true };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS:
      return { ...state, loading_emprestimos_export: false };
    case FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE:
      return { ...state, loading_emprestimos_export: false, error: action.payload };
    default:
      return state;
  }
};
