import api from '../../../services/axios';
import { LoginResponse } from '../api';

export async function loginApi(username: string, password: string): Promise<LoginResponse> {
    const response = await api.post<LoginResponse>('/login', {
        username,
        password
    });
    return response.data;
}
