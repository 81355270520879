import { Reducer } from 'react';
import {
  CLEAR_INTEGRACAO_VENDA_DATA,
  CLEAR_INTEGRACAO_VENDA_SORT_ORDER,
  FETCH_INTEGRACAO_VENDA_DATA,
  FETCH_INTEGRACAO_VENDA_FAILURE,
  FETCH_INTEGRACAO_VENDA_PDF,
  FETCH_INTEGRACAO_VENDA_PDF_FAILURE,
  FETCH_INTEGRACAO_VENDA_PDF_SUCCESS,
  FETCH_INTEGRACAO_VENDA_SUCCESS,
  FETCH_INTEGRACAO_VENDA_TOTALS,
  FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE,
  FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS,
  FETCH_INTEGRACAO_VENDA_XLS,
  FETCH_INTEGRACAO_VENDA_XLS_FAILURE,
  FETCH_INTEGRACAO_VENDA_XLS_SUCCESS,
  SET_INTEGRACAO_VENDA_SORT_ORDER,
} from './types';

export const integracaoVendaState = {
  data: [],
  integracaoVendaTotals: {},
  loadingTotals: false,
  loading: false,
  loadingIntegracaoVendaExport: false,
  error: null,
  errorTotals: null,
  sortField: null,
  sortOrder: null,
};

export const reducer: Reducer<any, any> = (state = integracaoVendaState, action: any) => {
  switch (action.type) {
    case SET_INTEGRACAO_VENDA_SORT_ORDER:
      return {
        ...state,
        sortField: action.payload.field,
        sortOrder: action.payload.order,
      };
    case CLEAR_INTEGRACAO_VENDA_SORT_ORDER:
      return {
        ...integracaoVendaState,
      };
    case FETCH_INTEGRACAO_VENDA_TOTALS:
      return { ...state, loadingTotals: true };
    case FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS:
      return { ...state, loadingTotals: false, integracaoVendaTotals: action.payload || {} };
    case FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE:
      return { ...state, loadingTotals: false, errorTotals: action.payload };
    case FETCH_INTEGRACAO_VENDA_DATA:
      return { ...state, loading: true };
    case FETCH_INTEGRACAO_VENDA_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_INTEGRACAO_VENDA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_INTEGRACAO_VENDA_DATA:
      return { ...integracaoVendaState };
    case FETCH_INTEGRACAO_VENDA_PDF:
      return { ...state, loadingVendaExport: true };
    case FETCH_INTEGRACAO_VENDA_PDF_SUCCESS:
      return { ...state, loadingVendaExport: false };
    case FETCH_INTEGRACAO_VENDA_PDF_FAILURE:
      return { ...state, loadingVendaExport: false };
    case FETCH_INTEGRACAO_VENDA_XLS:
      return { ...state, loadingVendaExport: true };
    case FETCH_INTEGRACAO_VENDA_XLS_SUCCESS:
      return { ...state, loadingVendaExport: false };
    case FETCH_INTEGRACAO_VENDA_XLS_FAILURE:
      return { ...state, loadingVendaExport: false };
    default:
      return state;
  }
};
