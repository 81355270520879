import { List } from 'lodash';
import { FETCH_ANTECIPACAO_DELETE_CONTA_DATA, FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE, FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS, FETCH_ANTECIPACAO_EMPRESTIMOS_DATA, FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE, FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS, FETCH_ANTECIPACAO_GET_DATA, FETCH_ANTECIPACAO_GET_FAILURE, FETCH_ANTECIPACAO_GET_SUCCESS, FETCH_ANTECIPACAO_POST_DATA, FETCH_ANTECIPACAO_POST_FAILURE, FETCH_ANTECIPACAO_POST_SUCCESS, RESET_URL_DATA, FETCH_ANTECIPACAO_EMPRESTIMOS_PDF, FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE, FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS, FETCH_ANTECIPACAO_EMPRESTIMOS_XLS, FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS, FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE } from './types';

export const fetchAntecipacaoGet = () => ({
  type: FETCH_ANTECIPACAO_GET_DATA,
});

export const fetchAntecipacaoGetSuccess = (data: any) => ({
  type: FETCH_ANTECIPACAO_GET_SUCCESS,
  payload: data,
});

export const fetchAntecipacaoGetFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_GET_FAILURE,
  payload: error,
});

export const fetchAntecipacaoPost = (json_data: Object) => ({
  type: FETCH_ANTECIPACAO_POST_DATA,
  payload: {
    json_data,
  },
});

export const fetchAntecipacaoPostSuccess = (data: any) => ({
  type: FETCH_ANTECIPACAO_POST_SUCCESS,
  payload: data,
});

export const fetchAntecipacaoPostFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_POST_FAILURE,
  payload: error,
});


export const fetchAntecipacaoDeleteConta = (conta_id: number) => ({
  type: FETCH_ANTECIPACAO_DELETE_CONTA_DATA,
  payload: conta_id
});

export const fetchAntecipacaoDeleteContaSuccess = (data: any) => ({
  type: FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS,
  payload: data,
});

export const fetchAntecipacaoDeleteContaFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE,
  payload: error,
});

export const resetUrlData = () => ({
  type: RESET_URL_DATA,
});

export const fetchAntecipacaoEmprestimosGet = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_DATA,
  payload,
});

export const fetchAntecipacaoEmprestimosGetSuccess = (data: any) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS,
  payload: data,
});

export const fetchAntecipacaoEmprestimosGetFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE,
  payload: error,
});

export const fetchAntecipacaoEmprestimosPdf = (payload: {
  company_id: number | number[];
  start_date: string;
  end_date: string;
  filters: {
    antecipacao_conta_id?: number;
  };
}) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_PDF,
  payload,
});

export const fetchAntecipacaoEmprestimosPdfSuccess = () => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS,
});

export const fetchAntecipacaoEmprestimosPdfFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE,
  payload: error,
});

export const fetchAntecipacaoEmprestimosXls = (payload: {
  company_id: number | number[];
  start_date: string;
  end_date: string;
  filters: {
    antecipacao_conta_id?: number;
  };
}) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_XLS,
  payload,
});

export const fetchAntecipacaoEmprestimosXlsSuccess = () => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS,
});

export const fetchAntecipacaoEmprestimosXlsFailure = (error: any) => ({
  type: FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE,
  payload: error,
});
