import { CaretUpOutlined, CarryOutOutlined, ClockCircleOutlined, EnvironmentOutlined, FieldNumberOutlined, FileProtectOutlined, LoadingOutlined, SlidersOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Col, Row, DatePicker, Form, InputNumber, Modal, Button, Input } from 'antd';
import moment from 'moment';
import { StyledModalContent } from './styled';

export const ModalGestaoTaxasActions = ({ record, handleDelete, handleEdit }: { record: any; handleDelete: any; handleEdit: any }) => {
  console.log(record);
  const [form] = Form.useForm();
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir esta taxa?',
      content: 'Esta ação é irreversível',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        handleDelete(record.gestaotaxaid);
      },
    });
  };
  const showEditConfirm = () => {
    Modal.confirm({
      title: 'Editar taxa de contrato',
      icon: null,
      content: (
        <StyledModalContent>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              modalidadenome: record.modalidadenome,
              bandeiranome: record.bandeiranome,
              adquirentenome: record.adquirentenome,
              empresanome: record.empresanome,
              estabelecimentonumero: record.estabelecimentonumero,
              gestaotaxavigenciainicio: moment(record.gestaotaxavigenciainicio).utc(),
              gestaotaxavigenciafim: moment(record.gestaotaxavigenciafim).utc(),
              gestaotaxavalortaxa: record.gestaotaxavalortaxa,
            }}
          >
            <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
              <Col span={24}>
                <Form.Item label="Empresa" name="empresanome" initialValue={record.empresanome}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Estabelecimento" name="estabelecimentonumero">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Adquirente" name="adquirentenome">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Modalidade" name="modalidadenome">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Bandeira" name="bandeiranome">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="gestaotaxavigenciainicio" label="Data Início Vigência" initialValue={moment(record.gestaotaxavigenciainicio)}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="gestaotaxavigenciafim" label="Data Fim Vigência" initialValue={moment(record.gestaotaxavigenciafim)}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="% Taxa" name="gestaotaxavalortaxa">
                  <InputNumber
                    formatter={(value) => value?.toString().replace('.', ',') + '%' || ''}
                    parser={(value) => {
                      const parsed = value?.replace(/%\s?/g, '').replace(',', '.');
                      return parsed ?? '';
                    }}
                    onChange={(value) => {
                      console.log('Valor enviado ao backend:', value);
                    }}
                    precision={3}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </StyledModalContent>
      ),
      okText: 'Salvar',
      cancelText: 'Cancelar',
      onOk() {
        const updatedData = form.getFieldsValue(true);
        if (updatedData.gestaotaxavigenciainicio) {
          updatedData.gestaotaxavigenciainicio = updatedData.gestaotaxavigenciainicio.startOf('day').format('YYYY-MM-DD');
        }
        if (updatedData.gestaotaxavigenciafim) {
          updatedData.gestaotaxavigenciafim = updatedData.gestaotaxavigenciafim.startOf('day').format('YYYY-MM-DD');
        }
        handleEdit(record.gestaotaxaid, updatedData);
      },
      okButtonProps: {
        style: { backgroundColor: '#252f64' },
      },
    });
  };
  return (
    <Card bordered={false} style={{ marginTop: 16 }}>
      <div style={{ marginBottom: 15 }}>
        <CaretUpOutlined style={{ marginRight: 10 }} />
        <strong>Registro de taxa por Contrato</strong>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            <FileProtectOutlined style={{ marginRight: 10 }} />
            <span>Data de cadastro: </span>
            <strong>{moment(record.gestaotaxacreatedat).format('DD/MM/YYYY HH:mm:ss')}</strong>
          </div>
          <div>
            <LoadingOutlined style={{ marginRight: 10 }} />
            <span>Última alteração: </span>
            <strong>{moment(record.gestaotaxaupdatedat).format('DD/MM/YYYY HH:mm:ss')}</strong>
          </div>
          <div>
            <UserOutlined style={{ marginRight: 10 }} />
            <span>Realizado por: </span>
            <strong>{record.usuarionome}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <CarryOutOutlined style={{ marginRight: 10 }} />
            <span>Data Início Vigência: </span>
            <strong>{moment(record.gestaotaxavigenciainicio).format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <ClockCircleOutlined style={{ marginRight: 10 }} />
            <span>Data Fim Vigência: </span>
            <strong>{moment(record.gestaotaxavigenciafim).format('DD/MM/YYYY')}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <EnvironmentOutlined style={{ marginRight: 10 }} />
            <span>Empresa: </span>
            <strong>{record.empresanome}</strong>
          </div>
          <div>
            <FieldNumberOutlined style={{ marginRight: 10 }} />
            <span>Estabelecimento: </span>
            <strong>{record.estabelecimentonumero}</strong>
          </div>
          <div>
            <SlidersOutlined style={{ marginRight: 10 }} />
            <span>Taxa: </span>
            <strong>{record.gestaotaxavalortaxa} %</strong>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <Button style={{ backgroundColor: '#252f64', marginRight: 5 }} type="primary" onClick={showEditConfirm}>
          Editar
        </Button>
        <Button style={{ backgroundColor: '#803525' }} type="primary" onClick={showDeleteConfirm}>
          Excluir
        </Button>
      </div>
    </Card>
  );
};
