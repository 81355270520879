import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/types';

interface PublicRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({
    component: Component,
    ...rest
}) => {
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);

    return (
        <Route
            {...rest}
            render={(props) =>
                accessToken ? (
                    <Redirect to={{ pathname: '/dashboard' }} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};
