import { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, SET_ACCESS_TOKEN, SET_ACCESS_TOKEN_FAILURE } from './types';

export const login = (email: string, password: string, customer: string) => ({
  type: LOGIN,
  payload: {
    email,
    password,
    customer,
  },
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginError = () => ({
  type: LOGIN_ERROR,
});

export function logout(logout: boolean): any {
  return {
    type: LOGOUT,
    payload: {
      logout,
    },
  };
}

export function setAccessToken(accessToken: any, user: any): any {
  return {
    type: SET_ACCESS_TOKEN,
    payload: {
      accessToken,
      user,
    },
  };
}

export function setAccessTokenFailure(): any {
  return {
    type: SET_ACCESS_TOKEN_FAILURE
  };
}
