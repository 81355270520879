import { Col, message, Row, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import MessageHeader from '../../components/MessageHeader';
import { Container } from '../../styles/GlobalStyles';
import { textIntegracaoVenda } from './interfaces';
import { StatCard } from '../../components/Charts/StatCard';
import { DollarCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import CollapseFilter from '../../components/CollapseFilter';
import { useEffect, useState } from 'react';
import { mapFiltersIntegracaoVendaForApi } from '../../utils/validateFilter';
import { fetchIntegracaoVenda, fetchIntegracaoVendaPdf, fetchIntegracaoVendaXls } from '../../store/states/integracaoVenda/actions';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { DropDownContent } from '../../components/DropDownContent';
import TableComponent from '../../components/TablePagination';
import { columnsIntegracaoVenda } from './interfaces/columnsIntegracaoVenda';
import { renderIntegracaoVenda } from './interfaces/integracaoVendaDetails';

function IntegracaoVendaScreen() {
  const dispatch = useDispatch();

  const integracaoVendaLoadingTotals = useSelector((state: RootState) => state.integracaoVenda.loadingTotals);
  const integracaoVendaTotals = useSelector((state: RootState) => state.integracaoVenda.integracaoVendaTotals);
  const integracaoVendaLoading = useSelector((state: RootState) => state.integracaoVenda.loading);
  const integracaoVendas = useSelector((state: RootState) => state.integracaoVenda.data);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const [perPage] = useState(10);
  const [pagesTotal, setPagesTotal] = useState(0);
  const [parceiros, setParceiros] = useState([]);
  const [adquirente, setAdquirentes] = useState([]);
  const [estabelecimento, setEstabelecimentos] = useState([]);
  const [bandeira, setBandeira] = useState([]);
  const [meiosCaptura, setMeiosCaptura] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [statusConciliacao, setStatusConciliacao] = useState([]);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const adquirentesAtivos = new Set(adquirente?.map((data: any) => data.id));
  const adquirentesFiltradas = adquirentes ? adquirentes.filter((adq: any) => adquirentesAtivos.has(adq.adquirenteid)) : [];
  const [selectedAdquirentes, setSelectedAdquirentes] = useState<string[]>([]);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerIntegracaoVenda');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const integracaoVendaLoadingExport = useSelector((state: RootState) => state.integracaoVenda.loadingIntegracaoVendaExport);
  

  useEffect(() => {
    if (integracaoVendas?.filters) {
      setParceiros(integracaoVendas.filters.parceiros);
      setAdquirentes(integracaoVendas.filters.adquirentes);
      setEstabelecimentos(integracaoVendas.filters.estabelecimentos);
      setBandeira(integracaoVendas.filters.bandeiras);
      setMeiosCaptura(integracaoVendas.filters.meios_captura);
      setModalidades(integracaoVendas.filters.modalidades);
      setStatusConciliacao(integracaoVendas.filters.status_conciliacao);
    }
    if (integracaoVendaTotals) {
      setPagesTotal(integracaoVendaTotals.totalList);
    }
    if (integracaoVendas) {
      setPagesTotal(integracaoVendaTotals.totalList);
      setTotalLiquidoSubFiltro(integracaoVendas.totalValorLiquido);
      setTotalBrutoSubFiltro(integracaoVendas.totalValorBruto);
    }
    console.log(adquirente)
  }, [integracaoVendas?.filters]);



  const fetchIntegracaoVendas = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersIntegracaoVendaForApi(filters);
    dispatch(
      fetchIntegracaoVenda({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    let newSelectedAdquirentes = [...selectedAdquirentes];
    if (newSelectedAdquirentes.includes(adquirenteName)) {
      newSelectedAdquirentes = newSelectedAdquirentes.filter((name) => name !== adquirenteName);
    } else {
      newSelectedAdquirentes.push(adquirenteName);
    }
    setSelectedAdquirentes(newSelectedAdquirentes);
    const newFilters = mapFiltersIntegracaoVendaForApi({ adquirentenome: newSelectedAdquirentes });
    setFilters(newFilters);
    fetchIntegracaoVendas(currentPage, pageSize, 'integracaodatavenda', 'DESC', newFilters);
    setActiveFilter(newSelectedAdquirentes.join(', '));
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerIntegracaoVenda');
    setFilters({});
    setSelectedAdquirentes([]);
    setActiveFilter(null);
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters((prev) => !prev);
    fetchIntegracaoVendas(1, pageSize, 'integracaodatavenda', 'DESC', {});
    setTableKey((prevKey) => prevKey + 1);
  };

  const handleExportXls = () => {
    message.success('Gerando o relatório de integração vendas no formato .xls, aguarde...');
    dispatch(
      fetchIntegracaoVendaXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersIntegracaoVendaForApi(filters),
      }),
    );
  };

  const handleExportPdf = () => {
    message.success('Gerando o relatório de integração vendas no formato .pdf, aguarde...');
    dispatch(
      fetchIntegracaoVendaPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        filters: mapFiltersIntegracaoVendaForApi(filters),
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'integracaodatavenda', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchIntegracaoVendas(page, pageSize, sortField, sortOrder, filters);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textIntegracaoVenda} />
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={integracaoVendaLoadingTotals}
                title="Total Bruto"
                value={(integracaoVendaTotals.totalValorBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={integracaoVendaLoadingTotals}
                title="Total Líquido"
                value={(integracaoVendaTotals.totalValorLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={integracaoVendaLoadingTotals}
                title="Média Total Líquido"
                value={(integracaoVendaTotals.mediaValorLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard loadingData={integracaoVendaLoadingTotals} title="Registros" value={integracaoVendaTotals.totalList || 0} icon={<DollarCircleOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={integracaoVendaLoadingTotals} title="Conciliados" value={integracaoVendaTotals.totalConciliados || 0} icon={<DollarCircleOutlined />} />
            </Col>
            <Col flex="5">
              <StatCard loadingData={integracaoVendaLoadingTotals} title="Não Conciliados" value={integracaoVendaTotals.totalNaoConciliados || 0} icon={<DollarCircleOutlined />} />
            </Col>
          </Row>
          {integracaoVendaLoading ? (
            <Skeleton active />
          ) : (
            <>
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                selectedAdquirentes={selectedAdquirentes}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10, gap: 5 }}>
                {integracaoVendas.list && (
                  <ButtonRefreshList loading={integracaoVendaLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                    Limpar Filtros
                  </ButtonRefreshList>
                )}
                {integracaoVendas.list && integracaoVendas.list.length !== 0 && <DropDownContent loadingExport={integracaoVendaLoadingExport} transacoes={integracaoVendas} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              </div>
            </>
          )}
          <TableComponent
            key={tableKey}
            loadingData={integracaoVendaLoading}
            data={integracaoVendas.list && integracaoVendas.list.length > 0 ? integracaoVendas.list : []}
            columns={columnsIntegracaoVenda(Array.from(parceiros), Array.from(adquirente), Array.from(bandeira), Array.from(estabelecimento), Array.from(meiosCaptura), Array.from(modalidades), Array.from(statusConciliacao))}
            rowKeyId="integracaoid"
            rowRender={renderIntegracaoVenda}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={pagesTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={totalBrutoSubFiltro}
            totalLiquidoSubFiltro={totalLiquidoSubFiltro}
            resetFilters={resetTableFilters}
            updateFilters={updateFilters}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}
export default IntegracaoVendaScreen;
