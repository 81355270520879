import { put, takeLatest, call } from 'redux-saga/effects';
import { loginError, setAccessToken, setAccessTokenFailure } from './actions';
import { loginApi } from '../../target-api/api';
import history from '../../../services/history';
import { message } from 'antd';
import { LOGIN, LOGOUT } from './types';

export function* loginSaga(action: any) {
  try {
    const { email, password } = action.payload;
    const { access_token, user } = yield call(loginApi, email, password);
    if (access_token) {
      localStorage.setItem('cardToken', access_token);
      localStorage.setItem('cardUser', JSON.stringify(user));
      yield put(setAccessToken(access_token, user));
      history.push('/dashboard');
      message.success('Bem vindo ao Card');
    } else {
      yield put(setAccessTokenFailure());
      message.warning('Não foi possível acessar o Card. Entre em contato com nosso time técnico para maiores informações!');
      history.push('/login');
    }
  } catch (error: any) {
    yield put(loginError());
    if (error.code === 'ERR_BAD_REQUEST') {
      message.warning('Usuário e/ou senha incorreto(s)!');
    } else if (error.code === 'ERR_NETWORK') {
      message.error('Falha ao realizar a conexão com servidor. Tente novamente mais tarde...');
    } else {
      message.error('Erro de requisição. Entre em contato com nossa equipe técnica.');
    }
  }
}

export function* logoutSaga() {
  localStorage.removeItem('cardToken');
  localStorage.removeItem('cardUser');
  localStorage.removeItem('startDate');
  localStorage.removeItem('endDate');
  localStorage.removeItem('showAcquirerPagamento');
  localStorage.removeItem('showAcquirerVenda');
  localStorage.removeItem('companyRef');
  history.push('/');
}

export function* watchLogin() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGOUT, logoutSaga);
}
