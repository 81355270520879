import { Collapse, Switch } from 'antd';
import { AdquirentesList, BancosList, CollapseFilterProps } from './interfaces';
import { StyledCollapseFilter } from './styled';

const { Panel } = Collapse;

function CollapseFilter({ isCollapseOpen, setIsCollapseOpen, setShowAcquirer, showAcquirer, handleAdquirenteSelect, handleBancoSelect, adquirentes, bancos, selectedAdquirentes, selectedBanco }: CollapseFilterProps) {

  return (
    <StyledCollapseFilter
      activeKey={isCollapseOpen ? '1' : undefined}
      onChange={() => {
        setIsCollapseOpen(!isCollapseOpen);
      }}
    >
      <Panel header="Filtros personalizados" key="1">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {handleBancoSelect && (
            <Switch
              checkedChildren="Adquirente"
              unCheckedChildren="Banco"
              checked={showAcquirer}
              onChange={(checked) => setShowAcquirer(checked)}
              style={{ marginRight: 35, backgroundColor: !showAcquirer ? '#252f64' : '#252f64' }}
            />
          )}
          
          <div className="scroll-container" style={{ flex: 1, overflowX: 'auto' }}>
            {showAcquirer ?
              <AdquirentesList
                onSelect={handleAdquirenteSelect}
                activeFilters={selectedAdquirentes}
                adquirentes={adquirentes} /> :
              <BancosList
              onSelect={(banco: string) => handleBancoSelect?.(banco)}
                activeFilter={selectedBanco || ''}
                bancos={bancos || []} />
            }
          </div>
        </div>
      </Panel>
    </StyledCollapseFilter>
  );
}

export default CollapseFilter;
