import { SearchOutlined } from '@ant-design/icons';
import { Button, Image, Select } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { bancoIDParaImagens } from '../../../styles/globalInterfaces';
import { round } from 'lodash';

const { Option } = Select;

function formatDocumentNumber(documentNumber: string | number): string {
  const numericOnly = documentNumber.toString().replace(/\D/g, '');
if (numericOnly.length === 11) {
    return `${numericOnly.slice(0, 2)}*.***.***-${numericOnly.slice(-2)}`;
  } else if (numericOnly.length === 14) {
    return `${numericOnly.slice(0, 2)}.***.***/****-${numericOnly.slice(-2)}`;
  }
  return numericOnly;
}


export const columnsAntecipacaoEmprestimos = (contas: any[], empresas: any[], bancos: any[]): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Data Assinatura',
      dataIndex: 'antecipacaoemprestimodataassinatura',
      key: 'antecipacaoemprestimodataassinatura',
      width: 170,
      sorter: true,
      render: (text: string) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
    },
    {
      title: 'Empresa',
      key: 'antecipacaoemprestimoempresaid',
      sorter: true,
      width: 300,
      render: (text: string, record: any) => {
        const empresa = empresas?.find((c: any) => c.empresaid === record.antecipacaoemprestimoempresaid);
        if (!empresa) return <span>Conta não encontrada</span>;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 8 }}>{`${empresa.empresanome}`}</span>
          </div>
        );
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select mode="multiple" allowClear placeholder="Selecione a(s) empresa(s)" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {empresas?.map((empresa: any) => (
              <Option key={empresa.empresaid} value={empresa.empresaid}>
                {empresa.empresanome}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value.includes(record.antecipacaoemprestimoempresaid);
      },
    },
    {
      title: 'Conta',
      dataIndex: 'antecipacaoemprestimoantecipacaoconta',
      key: 'antecipacaoemprestimoantecipacaoconta',
      width: 270,
      sorter: true,
      render: (text: string, record: any) => {
        const conta = contas?.find((c: any) => c.antecipacaocontaid === record.antecipacaoemprestimoantecipacaoconta);
        if (!conta) return <span>Conta não encontrada</span>;
        const banco = bancos?.find((b: any) => b.bancoid === conta.antecipacaocontabancoid);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image width={25} src={bancoIDParaImagens[parseInt(banco.bancoref, 10)]} alt={conta.banco_ref} preview={false} style={{ marginRight: 8 }} />
            <span style={{ marginLeft: 8 }}>{`${conta.antecipacaocontaagencia} / ${conta.antecipacaocontaconta}`}</span>
          </div>
        );
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }}>
            <Select mode="multiple" allowClear placeholder="Selecione a(s) conta(s)" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
              {contas?.map((account: any) => {
                const banco = bancos?.find((b: any) => b.bancoid === account.antecipacaocontabancoid);
                const bancoRef = banco ? parseInt(banco.bancoref, 10) : null;
                return (
                  <Option key={account.antecipacaocontaid} value={account.antecipacaocontaid}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {bancoRef !== null && <Image width={20} src={bancoIDParaImagens[bancoRef]} alt={banco ? banco.nome : 'Banco não encontrado'} preview={false} style={{ marginRight: 28 }} />}
                      <span style={{ marginLeft: 8 }}>{`${account.antecipacaocontaagencia} / ${account.antecipacaocontaconta}`}</span>
                    </div>
                  </Option>
                );
              })}
            </Select>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpar
            </Button>
          </div>
        );
      },
      onFilter: (value: any, record) => {
        return value.includes(record.antecipacaoemprestimoantecipacaoconta);
      },
    },
    {
      title: 'Documento',
      dataIndex: 'antecipacaoemprestimoantecipacaoconta',
      key: 'antecipacaoemprestimoantecipacaoconta',
      width: 180,
      sorter: true,
      render: (text: string, record: any) => {
        const conta = contas?.find((c: any) => c.antecipacaocontaid === record.antecipacaoemprestimoantecipacaoconta);
        if (!conta) return <span>Conta não encontrada</span>;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginLeft: 8 }}>{`${formatDocumentNumber(conta.antecipacaocontanumerodocumento)}`}</span>
          </div>
        );
      }
    },
    {
      title: 'Valor do Empréstimo',
      dataIndex: 'antecipacaoemprestimovalortotal',
      key: 'antecipacaoemprestimovalortotal',
      width: 200,
      sorter: true,
      render: (value: any) => {
        const formattedValue = parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return `${formattedValue}`;
      },
    },
    {
      title: 'Taxa Mensal',
      dataIndex: 'antecipacaoemprestimotaxamensal',
      key: 'antecipacaoemprestimotaxamensal',
      width: 150,
      sorter: true,
      render: (text: string, record: any) => (
        <div>
          <span>
            {record.antecipacaoemprestimotaxamensal > 0 ? round(parseFloat(record.antecipacaoemprestimotaxamensal) + parseFloat(record.antecipacaoemprestimotaxamensal), 2).toFixed(2) : round(record.antecipacaoemprestimotaxamensal, 2).toFixed(2)}%
          </span>
        </div>
      ),
    },
    {
      title: 'Taxa Efetiva',
      dataIndex: 'antecipacaoemprestimotaxaefetiva',
      key: 'antecipacaoemprestimotaxaefetiva',
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <div>
          <span>
            {record.antecipacaoemprestimotaxaefetiva > 0 ? round(parseFloat(record.antecipacaoemprestimotaxaefetiva) + parseFloat(record.antecipacaoemprestimotaxaefetiva), 2).toFixed(2) : round(record.antecipacaoemprestimotaxaefetiva, 2).toFixed(2)}%
          </span>
        </div>
      ),
    },
    {
      title: 'Data Vencimento',
      dataIndex: 'antecipacaoemprestimodatavencimento',
      key: 'antecipacaoemprestimodatavencimento',
      sorter: true,
      width: 170,
      render: (text: string) => (text ? moment(text).format('DD/MM/YYYY') : '---'),
    },
  ];
  return columns;
};
