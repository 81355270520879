import api from '../../../services/axios';
import { message } from 'antd';

export async function gestaoTaxaApi(): Promise<any> {
    const response = await api.get('/gestao-taxas/all');
    return response.data;
}

export async function changeRateApi(rateId: number, updatedData: any): Promise<any> {
    const response = await api.put(`/gestao-taxas/edit/${rateId}`, updatedData);
    return response.data;
}

export async function createRateApi(newRate: any): Promise<any> {
    const response = await api.post(`/gestao-taxas/create`, newRate);
    message.info(response.data.message);
    return response.data;
}

export async function updateRateApi(gestaoTaxaId: string, status: any): Promise<any> {
    const params = {
        gestao_taxa_id: gestaoTaxaId,
        status: status
    };
    const response = await api.put(`/gestao-taxas/update`, params);
    return response.data;
}

export async function importRateApi(clienteId: number, usuarioId: number, file: File): Promise<any> {
    console.log(clienteId, usuarioId, file);
    const formData = new FormData();
    formData.append('clienteid', clienteId.toString());
    formData.append('usuarioid', usuarioId.toString());
    formData.append('file', file);
    const response = await api.post('/gestao-taxas/upload-taxas', formData);
    return response.data;
}

export async function deleteRateApi(id: number): Promise<any> {
    const response = await api.delete(`/gestao-taxas/delete/${id}`);
    return response.data;
}