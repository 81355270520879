export function mapFiltersVendaForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.VENDAAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.VENDAAUTORIZACAO;
    delete apiFilters.VENDAAUTORIZACAO;
  }
  if (filters.VENDANSU) {
    apiFilters.numero_nsu = filters.VENDANSU;
    delete apiFilters.VENDANSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.VENDASTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.VENDASTATUSCONCILIACAO;
    delete apiFilters.VENDASTATUSCONCILIACAO;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersIntegracaoVendaForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.integracaoparceiroid) {
    apiFilters.parceiro_id = filters.integracaoparceiroid;
    delete apiFilters.integracaoparceiroid;
  }
  if (filters.integracaocodigoidentificador) {
    apiFilters.codigo_identificador = filters.integracaocodigoidentificador;
    delete apiFilters.integracaocodigoidentificador;
  }
  if (filters.adquirenteid) {
    apiFilters.adquirente_id = filters.adquirenteid;
    delete apiFilters.adquirenteid;
  }
  if (filters.integracaonumeroautorizacao) {
    apiFilters.numero_autorizacao = filters.integracaonumeroautorizacao;
    delete apiFilters.integracaonumeroautorizacao;
  }
  if (filters.estabelecimentoid) {
    apiFilters.estabelecimento_id = filters.estabelecimentoid;
    delete apiFilters.estabelecimentoid;
  }
  if (filters.integracaonumeronsu) {
    apiFilters.numero_nsu = filters.integracaonumeronsu;
    delete apiFilters.integracaonumeronsu;
  }
  if (filters.integracaonumerotid) {
    apiFilters.numero_tid = filters.integracaonumerotid;
    delete apiFilters.integracaonumerotid;
  }
  if (filters.bandeiraid) {
    apiFilters.bandeira_id = filters.bandeiraid;
    delete apiFilters.bandeiraid;
  }
  if (filters.meiocapturaid) {
    apiFilters.meio_captura_id = filters.meiocapturaid;
    delete apiFilters.meiocapturaid;
  }
  if (filters.modalidadeid) {
    apiFilters.modalidade_id = filters.modalidadeid;
    delete apiFilters.modalidadeid;
  }
  if (filters.integracaostatusconciliacao) {
    apiFilters.status_conciliacao = filters.integracaostatusconciliacao;
    delete apiFilters.integracaostatusconciliacao;
  }
  if (filters.integracaovalorbrutoparcela) {
    apiFilters.valor_bruto = filters.integracaovalorbrutoparcela;
    delete apiFilters.integracaovalorbrutoparcela;
  }
  
  return apiFilters;
}


export function mapFiltersPagamentoForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.PAGAMENTOAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.PAGAMENTOAUTORIZACAO;
    delete apiFilters.PAGAMENTOAUTORIZACAO;
  }
  if (filters.PAGAMENTONSU) {
    apiFilters.numero_nsu = filters.PAGAMENTONSU;
    delete apiFilters.PAGAMENTONSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.PAGAMENTOSTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.PAGAMENTOSTATUSCONCILIACAO;
    delete apiFilters.PAGAMENTOSTATUSCONCILIACAO;
  }
  if (filters.PAGAMENTOTIPOTRANSACAOID) {
    apiFilters.tipo_transacao = filters.PAGAMENTOTIPOTRANSACAOID;
    delete apiFilters.PAGAMENTOTIPOTRANSACAOID;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersAgendaRecebimentosForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.VENDAAUTORIZACAO) {
    apiFilters.numero_autorizacao = filters.VENDAAUTORIZACAO;
    delete apiFilters.VENDAAUTORIZACAO;
  }
  if (filters.VENDANSU) {
    apiFilters.numero_nsu = filters.VENDANSU;
    delete apiFilters.VENDANSU;
  }
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADEID) {
    apiFilters.modalidade_id = filters.MODALIDADEID;
    delete apiFilters.MODALIDADEID;
  }
  if (filters.VENDASTATUSCONCILIACAO) {
    apiFilters.status_conciliacao = filters.VENDASTATUSCONCILIACAO;
    delete apiFilters.VENDASTATUSCONCILIACAO;
  }
  if (filters.TAGTYPE) {
    apiFilters.tag_type = filters.TAGTYPE;
    delete apiFilters.TAGTYPE;
  }
  return apiFilters;
}

export function mapFiltersPagamentosConsolidadosForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.ESTABELECIMENTONUMERO) {
    apiFilters.estabelecimento_numero = filters.ESTABELECIMENTONUMERO;
    delete apiFilters.ESTABELECIMENTONUMERO;
  }
  if (filters.ADQUIRENTENOME) {
    apiFilters.adquirente_nome = filters.ADQUIRENTENOME;
    delete apiFilters.ADQUIRENTENOME;
  }
  if (filters.BANDEIRANOME) {
    apiFilters.bandeira_nome = filters.BANDEIRANOME;
    delete apiFilters.BANDEIRANOME;
  }
  if (filters.BANCONOME) {
    apiFilters.banco_nome = filters.BANCONOME;
    delete apiFilters.BANCONOME;
  }
  if (filters.MODALIDADENOME) {
    apiFilters.modalidade_nome = filters.MODALIDADENOME;
    delete apiFilters.MODALIDADENOME;
  }
  if (filters.CONTABANCARIA) {
    apiFilters.pagamento_conta = filters.CONTABANCARIA;
    delete apiFilters.CONTABANCARIA;
  }
  if (filters.EMPRESANOME) {
    apiFilters.empresa_nome = filters.EMPRESANOME;
    delete apiFilters.EMPRESANOME;
  }
  return apiFilters;
}

export function mapFiltersBankingOfxForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.ofxarquivoconta) {
    apiFilters.conta_numero = filters.ofxarquivoconta;
    delete apiFilters.ofxarquivoconta;
  }
  if (filters.adquirentenome) {
    apiFilters.adquirente_nome = filters.adquirentenome;
    delete apiFilters.adquirentenome;
  }
  if (filters.ofxtransacaostatusconciliacao) {
    apiFilters.status_conciliacao = filters.ofxtransacaostatusconciliacao;
    delete apiFilters.ofxtransacaostatusconciliacao;
  }
  if (filters.ofxtransacaodescricaodeposito) {
    apiFilters.descricao_deposito = filters.ofxtransacaodescricaodeposito;
    delete apiFilters.ofxtransacaodescricaodeposito;
  }
  if (filters.ofxtransacaovalordeposito) {
    apiFilters.valor_deposito = filters.ofxtransacaovalordeposito;
    delete apiFilters.ofxtransacaovalordeposito;
  }
  return apiFilters;
}

export function mapFiltersAntecipacaoEmprestimosForApi(filters: any) {
  const apiFilters = { ...filters };
  if (filters.antecipacaoemprestimoempresaid) {
    apiFilters.empresa_id = filters.antecipacaoemprestimoempresaid;
    delete apiFilters.antecipacaoemprestimoempresaid;
  }
  if (filters.antecipacaoemprestimoantecipacaoconta) {
    apiFilters.antecipacao_conta_id = filters.antecipacaoemprestimoantecipacaoconta;
    delete apiFilters.antecipacaoemprestimoantecipacaoconta;
  }
  if (filters.antecipacaoemprestimovalortotal) {
    apiFilters.valor_total = filters.antecipacaoemprestimovalortotal;
    delete apiFilters.antecipacaoemprestimovalortotal;
  }
  return apiFilters;
}
