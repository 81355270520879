export const FETCH_ANTECIPACAO_GET_DATA = 'FETCH_ANTECIPACAO_GET_DATA'
export const FETCH_ANTECIPACAO_GET_SUCCESS = 'FETCH_ANTECIPACAO_GET_SUCCESS'
export const FETCH_ANTECIPACAO_GET_FAILURE = 'FETCH_ANTECIPACAO_GET_FAILURE'
export const FETCH_ANTECIPACAO_POST_DATA = 'FETCH_ANTECIPACAO_POST_DATA'
export const FETCH_ANTECIPACAO_POST_SUCCESS = 'FETCH_ANTECIPACAO_POST_SUCCESS'
export const FETCH_ANTECIPACAO_POST_FAILURE = 'FETCH_ANTECIPACAO_POST_FAILURE'
export const RESET_URL_DATA = 'RESET_URL_DATA'
export const FETCH_ANTECIPACAO_DELETE_CONTA_DATA = 'FETCH_ANTECIPACAO_DELETE_CONTA_DATA'
export const FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS = 'FETCH_ANTECIPACAO_DELETE_CONTA_SUCCESS'
export const FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE = 'FETCH_ANTECIPACAO_DELETE_CONTA_FAILURE'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_DATA = 'FETCH_ANTECIPACAO_EMPRESTIMOS_DATA'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS = 'FETCH_ANTECIPACAO_EMPRESTIMOS_SUCCESS'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE = 'FETCH_ANTECIPACAO_EMPRESTIMOS_FAILURE'
export const FETCH_ANTECIPACAO_EMPRESTIMOS_PDF = 'FETCH_ANTECIPACAO_EMPRESTIMOS_PDF';
export const FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS = 'FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_SUCCESS';
export const FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE = 'FETCH_ANTECIPACAO_EMPRESTIMOS_PDF_FAILURE';
export const FETCH_ANTECIPACAO_EMPRESTIMOS_XLS = 'FETCH_ANTECIPACAO_EMPRESTIMOS_XLS';
export const FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS = 'FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_SUCCESS';
export const FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE = 'FETCH_ANTECIPACAO_EMPRESTIMOS_XLS_FAILURE';
