import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import { Provider } from 'react-redux';
import store from './store';
import { setAccessToken } from './store/states/auth/actions';
import { setDateRange } from './store/states/datas/actions';
import { updateCompanyID } from './store/states/empresa/actions';

const token = localStorage.getItem('cardToken');
const userInfo = localStorage.getItem('cardUser');
const startDate = localStorage.getItem('startDate');
const endDate = localStorage.getItem('endDate');
const companyRef = localStorage.getItem('companyRef');

if (token && userInfo) {
  store.dispatch(setAccessToken(token, JSON.parse(userInfo)));
  if (companyRef) {
    store.dispatch(updateCompanyID(companyRef.split(',').map(id => parseInt(id, 10))));
  }
  if (startDate && endDate) {
    store.dispatch(setDateRange(new Date(startDate), new Date(endDate)));
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
