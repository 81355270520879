import api from '../../../services/axios';

export async function estabelecimentoApi(empresa_id: number): Promise<any> {
    const response = await api.get('/estabelecimentos', {
        params: { empresa_id },
    });
    return response.data;
}

export async function updateEstabelecimentoApi(estabelecimentoId: string, status: boolean): Promise<any> {
    const params = {
        estabelecimento_id: estabelecimentoId,
        status: status
    };
    const response = await api.put('/estabelecimento/update', params);
    return response.data;
}
