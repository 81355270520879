import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';
import { useMemo } from 'react';
import { Card, Col, Empty, Row, Skeleton } from 'antd';
import { DollarCircleOutlined, CreditCardOutlined, RiseOutlined, FieldNumberOutlined, CalendarOutlined, FieldBinaryOutlined } from '@ant-design/icons';
import { StatCard } from '../StatCard';
import { Link } from 'react-router-dom';
import BarChartDash from '../BarChartDash';
import { differenceInDays, differenceInCalendarMonths } from 'date-fns';
import { formatDate } from '../../../utils/validateDates';
import backgroundImage from '../../../assets/global/background.svg';

export function ChartDashPagamento({ pagamentoTotals, pagamentoChartData }: any) {
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const pagamentoLoadingTotals = useSelector((state: RootState) => state.pagamento.loadingTotals);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const daysInPeriod = differenceInDays(endDate, startDate);

  const renderTooltipValue = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  function safeParseFloat(value: any): number | null {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      const result = parseFloat(value.replace(',', '.'));
      return isNaN(result) ? null : result;
    }
    return null;
  }

  const dataForChart = useMemo(() => {
    const shouldGroupByMonth = differenceInCalendarMonths(endDate, startDate) > 0;
    const groupedData: { [key: string]: { [adquirente: string]: number } } = {};
    pagamentoChartData.forEach((transaction: any) => {
      const { PERIODO, ADQUIRENTENOME, TOTALVALORBRUTO } = transaction;
      const valorBruto = safeParseFloat(TOTALVALORBRUTO);
      let key;
      if (shouldGroupByMonth) {
        const date = new Date(formatDate(PERIODO));
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      } else {
        key = PERIODO;
      }
      if (!groupedData[key]) {
        groupedData[key] = {};
      }
      if (valorBruto !== null) {
        if (typeof groupedData[key][ADQUIRENTENOME] === 'number') {
          groupedData[key][ADQUIRENTENOME] += valorBruto;
        } else {
          groupedData[key][ADQUIRENTENOME] = valorBruto;
        }
      }
    });
    const chartData = Object.entries(groupedData).map(([key, adquirentes]) => {
      const total = Object.values(adquirentes).reduce((acc, value) => acc + value, 0);
      return {
        PERIODO: key,
        total,
        ...adquirentes,
      };
    });
    chartData.sort((a, b) => {
      const dateA = shouldGroupByMonth ? new Date(parseInt(a.PERIODO.split('-')[0]), parseInt(a.PERIODO.split('-')[1]) - 1) : new Date(a.PERIODO);
      const dateB = shouldGroupByMonth ? new Date(parseInt(b.PERIODO.split('-')[0]), parseInt(b.PERIODO.split('-')[1]) - 1) : new Date(b.PERIODO);
      return dateA.getTime() - dateB.getTime();
    });
    return chartData;
  }, [pagamentoChartData]);

  const groupingType = useMemo(() => {
    if (differenceInCalendarMonths(new Date(endDate), new Date(startDate)) > 0) {
      return 'month';
    } else {
      return 'day';
    }
  }, [startDate, endDate]);

  const adquirenteNames = useMemo(() => {
    const namesSet = new Set(pagamentoChartData.map((item: any) => item.ADQUIRENTENOME));
    return Array.from(namesSet);
  }, [pagamentoChartData]);

  return (
    <>
      <Row gutter={16}>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Total Pagamentos" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentoTotals.pagamentoTotalBruto || 0)} icon={<DollarCircleOutlined />} />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Adquirentes" value={pagamentoTotals.pagamentoTotalAdquirentes || 0} icon={<CreditCardOutlined />} />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Maior Pagamento" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentoTotals.pagamentoValorMaximo || 0)} icon={<FieldBinaryOutlined />} />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Média Pagamentos" value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentoTotals.pagamentoValorMedio || 0)} icon={<RiseOutlined />} />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Registros" value={parseFloat(pagamentoTotals.pagamentoTotalRegistros || 0).toLocaleString('pt-BR')} icon={<FieldNumberOutlined />} />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <Link to="/pagamento">
            <StatCard loadingData={pagamentoLoadingTotals} title="Período (dias)" value={!Number.isNaN(daysInPeriod) ? daysInPeriod : 0} icon={<CalendarOutlined style={{ color: 'currentColor' }} />} />
          </Link>
        </Col>
      </Row>
      {pagamentoLoading ? (
        <>
          <Card title={<Skeleton.Input style={{ width: 200 }} active />} bordered={false} style={{ marginTop: 10, background: '#252f64' }}>
            <Skeleton active />
          </Card>
          <Card title={<Skeleton.Input style={{ width: 200 }} active />} bordered={false} style={{ marginTop: 10, background: '#252f64' }}>
            <Skeleton active />
          </Card>
        </>
      ) : (
        <>
          <Card
            title={<div style={{ color: '#fff' }}>Gráfico de Pagamentos</div>}
            bordered={false}
            style={{ cursor: 'pointer', marginTop: 10, background: `url(${backgroundImage})`, backgroundColor: '#252f64', backgroundBlendMode: 'multiply', backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            {dataForChart.length > 0 ? (
              <Link to="/pagamento">
                <BarChartDash dataForChart={dataForChart} adquirenteNames={adquirenteNames} renderTooltipValue={renderTooltipValue} typeXAxis="PERIODO" groupingType={groupingType} />
              </Link>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ color: 'white' }}>Não há dados disponíveis</span>} />
            )}
          </Card>
        </>
      )}
    </>
  );
}
