import moment from 'moment';
import api from '../../../services/axios';
import saveAs from 'file-saver';
import { message } from 'antd';

export async function vendaApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/venda/all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function vendaAuditAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/venda/vendaAuditAnalytics';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function vendaAnalyticsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/venda/vendaChartAnalytics';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function vendaChartDataApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/venda/vendaChartData';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function vendaTotalsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<any> {
    const baseURL = '/venda/vendaTotals';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function vendaPdfApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/venda/export/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        // Identifica o tipo do arquivo com base no cabeçalho Content-Type
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_venda';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/pdf') {
            fileName += '.pdf';
        } else {
            fileName += '.bin'; // Tipo genérico como fallback
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName); 
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}

export async function vendaXlsApi(company_id: number[] | number, start_date: string, end_date: string, filters: Record<string, any> = {}): Promise<void> {
    const baseURL = '/venda/export/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_venda';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileName += '.xlsx';
        } else {
            fileName += '.bin'; 
        }
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, fileName);
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}

export async function vendaAuditadaApiXls(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/venda/export-audit/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_venda_auditada';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileName += '.xlsx';
        } else {
            fileName += '.bin';
        }
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, fileName);
    } catch (error) {
        message.error(`Erro ao baixar o arquivo auditado em XLSX: ${error}`);
    }
}

export async function vendaAuditadaApiPdf(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/venda/export-audit/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        // Identifica o tipo do arquivo com base no cabeçalho Content-Type
        const contentType = response.headers['content-type'];
        let fileName = 'relatorio_venda_auditada';

        if (contentType === 'application/zip' || contentType === 'application/x-zip-compressed') {
            fileName += '.zip';
        } else if (contentType === 'application/pdf') {
            fileName += '.pdf';
        } else {
            fileName += '.bin'; // Tipo genérico como fallback
        }
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, fileName); 
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}
