import { takeLatest, call, put } from 'redux-saga/effects';
import { empresaApi } from '../../target-api/api';
import { toast } from 'react-toastify';
import { fetchCompanySuccess } from './actions';
import { FETCH_EMPRESA_DATA } from './types';

export function* fetchCompanySaga(action: any) {
  try {
    const { user_id } = action.payload;
    const data: any[] = yield call(empresaApi, user_id);
    if (data !== undefined) {
      let companyRef: any = [];
      data.map((item: any) => {
        companyRef.push(item.empresaid);
      });
      localStorage.setItem('companyRef', companyRef);
      yield put(fetchCompanySuccess(data));
    } else {
      toast.warn('Falha ao retornar lista de empresas!');
    }
  } catch (error: any) {
    toast.error(error);
  }
}

export function* watchEmpresaSaga() {
  yield takeLatest(FETCH_EMPRESA_DATA, fetchCompanySaga);
}
