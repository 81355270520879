export const SET_INTEGRACAO_VENDA_SORT_ORDER = 'SET_INTEGRACAO_VENDA_SORT_ORDER';
export const CLEAR_INTEGRACAO_VENDA_SORT_ORDER = 'CLEAR_INTEGRACAO_VENDA_SORT_ORDER';
export const CLEAR_INTEGRACAO_VENDA_DATA = 'CLEAR_INTEGRACAO_VENDA_DATA';
export const FETCH_INTEGRACAO_VENDA_TOTALS = 'FETCH_INTEGRACAO_VENDA_TOTALS';
export const FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS = 'FETCH_INTEGRACAO_VENDA_TOTALS_SUCCESS';
export const FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE = 'FETCH_INTEGRACAO_VENDA_TOTALS_FAILURE';
export const FETCH_INTEGRACAO_VENDA_DATA = 'FETCH_INTEGRACAO_VENDA_DATA';
export const FETCH_INTEGRACAO_VENDA_SUCCESS = 'FETCH_INTEGRACAO_VENDA_SUCCESS';
export const FETCH_INTEGRACAO_VENDA_FAILURE = 'FETCH_INTEGRACAO_VENDA_FAILURE';
export const FETCH_INTEGRACAO_VENDA_PDF = 'FETCH_INTEGRACAO_VENDA_PDF';
export const FETCH_INTEGRACAO_VENDA_PDF_SUCCESS = 'FETCH_INTEGRACAO_VENDA_PDF_SUCCESS';
export const FETCH_INTEGRACAO_VENDA_PDF_FAILURE = 'FETCH_INTEGRACAO_VENDA_PDF_FAILURE';
export const FETCH_INTEGRACAO_VENDA_XLS = 'FETCH_INTEGRACAO_VENDA_XLS';
export const FETCH_INTEGRACAO_VENDA_XLS_SUCCESS = 'FETCH_INTEGRACAO_VENDA_XLS_SUCCESS';
export const FETCH_INTEGRACAO_VENDA_XLS_FAILURE = 'FETCH_INTEGRACAO_VENDA_XLS_FAILURE';