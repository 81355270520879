import styled, { keyframes } from 'styled-components';
import BackgroundImage from '../../assets/landing-page/background-landing.png';
import { Typography, Image as AntImage, Button } from 'antd';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: transparent !important;
  background-image: url('${BackgroundImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  margin: 0 auto;
  overflow: hidden;
  
  @media (max-width: 768px) {
    background-repeat: repeat;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1%;
  padding: 0 10%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FlexItemStatic = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 15px;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: center;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const FlexItem = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: center;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  line-height: 119.5%;
  color: #C9E437;
  font-family: 'Poppins';
  font-size: 300%;
  text-align: left;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const LandingButton = styled(Button)`
  background-color: #9fb429 !important;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  animation: ${pulseAnimation} 2s infinite;
  &:hover {
    background-color: #a6bf2f !important;
    transform: scale(1.05);
  }
`;

export const ProposalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  position: relative;
`;

export const StyledGroupFinlyImage = styled.div`
  img {
    width: 50%;
    max-width: 450px;

    @media (max-width: 540px), (max-height: 667px) {
      margin-top: 5%;
    }
  }
`;

export const StyledImage = styled(AntImage)`
  width: 80%;
  max-width: 450px;

  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 540px), (max-height: 667px) {
    width: 100%;
    margin-top: 5%;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  background-color: #252f64;
  color: #ffffff;
  padding: 25px 0;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  a {
    color: #C9E437;
    margin: 0 10px;
    transition: color 0.3s ease;

    &:hover {
      color: #ffffff;
    }
  }
`;

export const SocialMediaIcons = styled.div`
  font-size: 24px;
  margin-bottom: 10px;

  i {
    margin: 0 15px;
    cursor: pointer;
  }
`;

export const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const ButtonBackToTop = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;